import { Callout } from '@shared/ui/Callout';
import { GenericLink } from '@shared/ui/GenericLink';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateApplicationForm } from '../forms/CreateApplication';
import { CreateApplicationItemForm } from '../forms/CreateApplicationItem';
import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { useLinkStateWithPathname } from '@shared/hooks/useLinkStateWithPathname';

export const CabinetCustomerCreateApplication: React.FC = () => {
  const { t } = useTranslation();
  const linkState = useLinkStateWithPathname();

  const calloutIconOptimized = useGetIconOptimized('light-bulb');
  const calloutIconData = {
    optimized: calloutIconOptimized,
    className: 'w-[42px] h-auto',
  };

  return (
    <>
      {/*<Callout icon={calloutIconData} className="text-sm mb-10">*/}
      {/*  <div>*/}
      {/*    {t('have-troubles-to-create-application')} -{' '}*/}
      {/*    <GenericLink*/}
      {/*      type="page"*/}
      {/*      to="/questions-answers/question-category-get-help-2"*/}
      {/*      className="text-shamrock hover:underline"*/}
      {/*      state={linkState}*/}
      {/*    >*/}
      {/*      {t('question-category-get-help-2')}*/}
      {/*    </GenericLink>*/}
      {/*  </div>*/}
      {/*</Callout>*/}
      
      {/*<CreateApplicationItemForm />*/}
      {/*<CreateApplicationForm />*/}
    </>
  );
};
